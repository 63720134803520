export function onCookieBotLoad(
    setLoadSegment: (value: boolean) => void
): void {
    // This effect handles the relationship between the cookie consent
    // and segment (analytics). Until the user hasn't consented to the use of cookies
    // we will not load segment.
    // Check if the user has already accepted the cookies
    const handleCookieBotResponse = (): void => {
        if (
            window.Cookiebot &&
            window.Cookiebot.consented &&
            window.Cookiebot.consent.preferences &&
            window.Cookiebot.consent.statistics &&
            window.Cookiebot.consent.marketing
        ) {
            setLoadSegment(true);
        }
        window.removeEventListener(
            'CookiebotOnAccept',
            handleCookieBotResponse
        );
    };

    if (window.Cookiebot && window.Cookiebot.hasResponse) {
        handleCookieBotResponse();
    } else {
        window.addEventListener('CookiebotOnAccept', handleCookieBotResponse);
    }
}
