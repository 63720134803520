/**
 * Module used for constants.
 *
 * Do not import anything here
 */

/**
 * Project environments
 */
const PROJECT_ENVS = {
    TEST: 'test',
    LOCAL: 'local',
    STAGE: 'stage',
    PRODUCTION: 'prod',
};

const PROJECT_NAME = {
    FONOMA: 'fonoma',
    RETOPE: 'retope',
};

const LOGIN_PATH = '/login';
const UTM_NAMES = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_content',
    'utm_term',
    'utm_adset',
    'utm_banner',
];

const utmPropertyNameMap = {
    utm_campaign: 'name',
    utm_content: 'content',
    utm_medium: 'medium',
    utm_source: 'source',
    utm_banner: 'banner',
    utm_adset: 'adset',
};

const FACEBOOK_FBP_COOKIE_NAME = '_fbp';
const FACEBOOK_FBC_COOKIE_NAME = '_fbc';

const TIKTOK_QUERY_NAME = 'ttclid';
/**
 * Allowed languages
 */
const LANGUAGES = {
    SPANISH: 'es',
    ENGLISH: 'en',
};

const CURRENCIES = {
    USD: 'USD',
    EUR: 'EUR',
    CUC: 'CUC',
};

const OPERATORS = {
    NAUTA: 'Nauta',
    CUBACEL: 'Cubacel',
    DATA_PLANS: 'Data_Plans',
};

const SERVICES = {
    INTERNET: 'internet',
    PHONE: 'phone',
    DATA_PLANS: 'data_plans',
};

const CONTACT_FIELDS = {
    phone_number_prefix: 'phone_number_prefix',
    phone_number_suffix: 'phone_number_suffix',
    internet_user_id: 'internet_user_id',
};

const PROJECT_CLIENT = 'web';
const WEB_CLIENT_HEADER = {'X-Client': PROJECT_CLIENT};

const INSTRUCTIONS_LINK =
    'https://docs.google.com/document/d/1tdjlpD1LFC6hxemWll9o9ltL1DqcUl64X-B67V1mlwc/edit?usp=sharing';

const PREFERENCES_COOKIE = 'prefs';

const PREFERENCES_COOKIE_SEPARATOR = ':';

const EVENTS = {
    // Language
    LANGUAGE_CHANGED: 'Language changed',
    // Landings
    LOGOUT: 'Logout',
    DISCOUNT_CARD_CLICKED: 'Discount card clicked',
};

const PAGE_EVENTS = {
    REDEEM_PROMO_CODE: 'Landing Redeem Invite Promo Code',
    LANDING_INVITE_PAGE: 'Landing Invite',
    LANDING_RETENTION_PAGE: 'Landing Retention',
    LANDING_ACQUISITION_PAGE: 'Landing Acquisition',
    LANDING_HOME_PAGE: 'Landing Home',
};

const INTER_FAMILIES = [300, 400, 600, 700];

const OBSCURE_HEADER_NAME = 'X-fnm-Auth';

const OBSCURE_COOKIE_NAME = 'rpnbuytqan';

const OBSCURE_VALUE = '7sm6mpvndx';

const RECIPIENT_URL_PARAM = 'recipient';

const LANDING_TEMPLATE_NAMES = {
    // Phone
    ACTIVE_PROMO_PHONE_RETENTION: 'active_phone_promo_retention',
    ANNOUNCED_PROMO_PHONE_RETENTION: 'announced_phone_promo_retention',
    NO_PROMO_PHONE_RETENTION: 'phone_extra_0',
    ACTIVE_PROMO_PHONE_ACQUISITION: 'active_phone_promo_acquisition',
    ANNOUNCED_PROMO_PHONE_ACQUISITION: 'announced_phone_promo_acquisition',
    NO_PROMO_PHONE_ACQUISITION: 'phone_extra_5',
    // Nauta
    NO_PROMO_NAUTA_RETENTION: 'nauta_extra_0',
    NO_PROMO_NAUTA_ACQUISITION: 'nauta_extra_4',
    //Data Plans
    ACTIVE_PROMO_DATA_PLANS_RETENTION: 'active_data_plans_promo_retention',
    ANNOUNCED_PROMO_DATA_PLANS_RETENTION:
        'announced_data_plans_promo_retention',
    NO_PROMO_DATA_PLANS_RETENTION: 'data_plans_extra_0',
    ACTIVE_PROMO_DATA_PLANS_ACQUISITION: 'active_data_plans_promo_acquisition',
    ANNOUNCED_PROMO_DATA_PLANS_ACQUISITION:
        'announced_data_plans_promo_acquisition',
    NO_PROMO_DATA_PLANS_ACQUISITION: 'data_plans_extra_7',
};

const LANDINGS_TEMPLATE_NAME_PATH_MAP = {
    // Phone
    [LANDING_TEMPLATE_NAMES.ACTIVE_PROMO_PHONE_RETENTION]:
        '/promo/recarga_cuba',
    [LANDING_TEMPLATE_NAMES.ANNOUNCED_PROMO_PHONE_RETENTION]:
        '/promo/recarga_cuba',
    [LANDING_TEMPLATE_NAMES.NO_PROMO_PHONE_RETENTION]: '/promo/recarga_cuba',
    [LANDING_TEMPLATE_NAMES.ACTIVE_PROMO_PHONE_ACQUISITION]:
        '/promo/recarga_cubacel_cuba',
    [LANDING_TEMPLATE_NAMES.ANNOUNCED_PROMO_PHONE_ACQUISITION]:
        '/promo/recarga_cubacel_cuba',
    [LANDING_TEMPLATE_NAMES.NO_PROMO_PHONE_ACQUISITION]:
        '/promo/recarga_cubacel_cuba',
    // Nauta
    [LANDING_TEMPLATE_NAMES.NO_PROMO_NAUTA_RETENTION]: '/promo/recarga_nauta',
    [LANDING_TEMPLATE_NAMES.NO_PROMO_NAUTA_ACQUISITION]:
        '/promo/recarga_nauta_cuba',
    // Data Plans
    [LANDING_TEMPLATE_NAMES.ACTIVE_PROMO_DATA_PLANS_RETENTION]:
        '/promo/planes_datos',
    [LANDING_TEMPLATE_NAMES.ANNOUNCED_PROMO_DATA_PLANS_RETENTION]:
        '/promo/planes_datos',
    [LANDING_TEMPLATE_NAMES.NO_PROMO_DATA_PLANS_RETENTION]:
        '/promo/planes_datos',
    [LANDING_TEMPLATE_NAMES.ACTIVE_PROMO_DATA_PLANS_ACQUISITION]:
        '/promo/planes_datos_cuba',
    [LANDING_TEMPLATE_NAMES.ANNOUNCED_PROMO_DATA_PLANS_ACQUISITION]:
        '/promo/planes_datos_cuba',
    [LANDING_TEMPLATE_NAMES.NO_PROMO_DATA_PLANS_ACQUISITION]:
        '/promo/planes_datos_cuba',
};

const SPANISH_REVIEWS = 'spanish_landing';
const ENGLISH_REVIEWS = 'english_landing';

// Rating stats
const RATING_STATS_FONOMA = {
    RATING_VALUE: '4.85',
    REVIEW_COUNT: '3002',
};

const RATING_STATS_TELOA = {
    RATING_VALUE: '4.7',
    REVIEW_COUNT: '458',
};

export {
    LANGUAGES,
    PROJECT_ENVS,
    INSTRUCTIONS_LINK,
    PREFERENCES_COOKIE,
    PREFERENCES_COOKIE_SEPARATOR,
    CURRENCIES,
    EVENTS,
    PAGE_EVENTS,
    INTER_FAMILIES,
    OBSCURE_HEADER_NAME,
    OBSCURE_COOKIE_NAME,
    OBSCURE_VALUE,
    OPERATORS,
    SERVICES,
    CONTACT_FIELDS,
    LOGIN_PATH,
    UTM_NAMES,
    utmPropertyNameMap,
    FACEBOOK_FBP_COOKIE_NAME,
    FACEBOOK_FBC_COOKIE_NAME,
    TIKTOK_QUERY_NAME,
    LANDING_TEMPLATE_NAMES,
    RECIPIENT_URL_PARAM,
    WEB_CLIENT_HEADER,
    SPANISH_REVIEWS,
    ENGLISH_REVIEWS,
    LANDINGS_TEMPLATE_NAME_PATH_MAP,
    RATING_STATS_FONOMA,
    RATING_STATS_TELOA,
    PROJECT_NAME,
};
